export const BREAKPOINTS = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1980,
  xxxl: 2500,
}

export const supportEmail = 'marketedge@the-atlas.com'
export const consultationEmail = 'marketingconsult@the-atlas.com'

export const supportEmailLink = `<a class="underline" href="mailto:${supportEmail}">${supportEmail}</a>`

export const marketingHome = 'https://marketedge.the-atlas.com'
export const marketingRequestDemo = marketingHome + '/request-demo/'

export const COOKIE_NAMES = {
  REFRESH_TOKEN: 'auth._refresh_token.local',
  REFRESH_TOKEN_EXPIRATION: 'auth._refresh_token_expiration.local',
  TOKEN: 'auth._token.local',
  TOKEN_EXPIRATION: 'auth._token_expiration.local',
}

export const emptyTypeaheadErrorMessage =
  'No results found. Please retry search with a new or more specific term.'

export const accountSearchRegex = new RegExp(
  'city|of|\\Wthe\\W|\\Wand\\W|district|water|utility|authority|village|township|county|government|^town|\\Wtown',
  'gi'
)

export const rebidType = 'Likely Contract Re-Bid'
