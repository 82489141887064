<script setup lang="ts">
import { computed, defineProps } from 'vue'
import Spinner from '@/components/Spinner.vue'

const props = defineProps({
  height: Number,
})

const styles = computed(() => {
  if (props.height) {
    return { height: `${props.height}px` }
  }
  return { height: '100%' }
})
</script>

<template>
  <div class="card" :style="styles">
    <Spinner />
  </div>
</template>

<style scoped lang="scss">
.card {
  @apply flex items-center justify-center;
  width: 100%;
  position: relative;
}
</style>
