import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { useStore } from '@/store/main'
import { isEmpty, find } from 'lodash-es'
import type { ProductUnitKey, ProductUnitTitles } from '@/types/Platform'
import type { StoreFilterNames } from '@/store/main'
import { useAuthStore } from '@/store/auth'
import getUserErrorMessage, {
  customErrorMessage,
} from '@/utils/getUserErrorMessage'
import Bugsnag from '@bugsnag/js'

export const ROUTE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MY_KEYWORDS: '/my-keywords',
  KEYWORD_SEARCH: '/keyword-search',
  TERMS_OF_USE: '/terms-of-use',
  MY_ACCOUNTS: '/my-accounts',
  ACCOUNT_SEARCH: '/account-search',
  ACCOUNTS: '/accounts', // redirects to ACCOUNT_SEARCH
  ACCOUNT_DEEP_DIVE: '/accounts/:slug',
  CAMPAIGN_KEYWORDS: '/campaigns/by-keyword',
  CAMPAIGN_PERSONAS: '/campaigns/by-persona',
  PROCUREMENT_OPPORTUNITIES: '/procurement/my-opportunities',
  PROCUREMENT_SEARCH: '/procurement/search',
  PROCUREMENT_ALERTS: '/procurement/alerts',
  PROCUREMENT_DETAILS: '/procurement/opportunities/:id',
  PRIVACY_POLICY: '/privacy-policy',
  UPSELL: '/upgrade/:slug',
  SETTINGS: '/settings',
  CREATE_PASSWORD: '/create-password/:token',
  RESET_PASSWORD: '/reset-password/:token',
  FORGOT_PASSWORD: '/forgot-password',
  RFP_PDF: '/rfp-pdf/:docId?',
  NOT_FOUND: '/:pathMatch(.*)*', // 404

  // Help pages
  HELP_SMART_KEYWORDS_GUIDE: '/help/smart-keywords-guide',
  HELP_PROCUREMENT_SEARCH: '/help/procurement-search',
  HELP_INTENT_DATA_PLAY: '/help/intent-data-play',
  HELP_COMPETITOR_PLAY: '/help/competitor-research-play',
  HELP_REBID_PLAY: '/help/rebid-play',
  HELP_BUYING_PLAY: '/help/buying-committee-play',
} as const

export const COMMUNICATION_SETTINGS_LINK = `${ROUTE_PATHS.SETTINGS}#communication`

export function getAccountLink(link: string) {
  return ('/accounts/' + link) as RoutePath
}

export function getProcurementLink(id: number | string) {
  return ('/procurement/opportunities/' + id) as RoutePath
}

export function getPdfLink(id: string) {
  return ('/rfp-pdf/' + id) as RoutePath
}

export function getUpsellLink(unit: ProductUnitTitles) {
  return ROUTE_PATHS.UPSELL.replace(':slug', unit.toLowerCase())
}

export type RoutePath = typeof ROUTE_PATHS[keyof typeof ROUTE_PATHS]

export type RedirectRoute = {
  path: string
  redirect: string
}

export type NavLink = {
  text: string
  to: RoutePath
  dataTest?: string
}

export const productUnitSubNavMap: { [key: string]: NavLink[] } = {
  accounts: [
    {
      text: 'My Accounts',
      to: ROUTE_PATHS.MY_ACCOUNTS,
      dataTest: 'nav-my-accounts',
    },
    {
      text: 'Account Search',
      to: ROUTE_PATHS.ACCOUNT_SEARCH,
      dataTest: 'nav-account-search',
    },
  ],
  keywords: [
    {
      text: 'My Keywords',
      to: ROUTE_PATHS.MY_KEYWORDS,
      dataTest: 'nav-my-keywords',
    },
    {
      text: 'Keyword Search',
      to: ROUTE_PATHS.KEYWORD_SEARCH,
      dataTest: 'nav-keyword-search',
    },
  ],
  campaign: [
    {
      text: 'By Persona',
      to: ROUTE_PATHS.CAMPAIGN_PERSONAS,
      dataTest: 'nav-campaign-by-persona',
    },
    {
      text: 'By Keyword',
      to: ROUTE_PATHS.CAMPAIGN_KEYWORDS,
      dataTest: 'nav-campaign-by-keyword',
    },
  ],
  procurement: [
    {
      text: 'My Opportunities',
      to: ROUTE_PATHS.PROCUREMENT_OPPORTUNITIES,
      dataTest: 'nav-my-opportunities',
    },
    {
      text: 'Search',
      to: ROUTE_PATHS.PROCUREMENT_SEARCH,
      dataTest: 'nav-procurement-search',
    },
    {
      text: 'Alerts',
      to: ROUTE_PATHS.PROCUREMENT_ALERTS,
      dataTest: 'nav-procurement-alerts',
    },
  ],
}

export type AtlasMeta = {
  layout: string // type this later
  productUnit?: ProductUnitKey // type this later
  requiresAuth?: boolean
  savedFilterKey?: StoreFilterNames
  readOnlyFilters?: boolean
}

type AtlasRoute = RouteRecordRaw & {
  meta: AtlasMeta
}

const productUnitToRouteMap: { [key: string]: RoutePath } = {
  accounts: ROUTE_PATHS.MY_ACCOUNTS,
  keywords: ROUTE_PATHS.MY_KEYWORDS,
  campaign: ROUTE_PATHS.CAMPAIGN_PERSONAS,
  procurement: ROUTE_PATHS.PROCUREMENT_OPPORTUNITIES,
}

type PublicRoute = AtlasRoute & { meta: { requiresAuth: false } }

const publicRoutes: PublicRoute[] = [
  {
    path: ROUTE_PATHS.LOGIN,
    name: 'Login',
    meta: {
      layout: 'Public',
      requiresAuth: false,
    },
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: ROUTE_PATHS.TERMS_OF_USE,
    name: 'Terms Of Use',
    meta: {
      layout: 'Text',
      requiresAuth: false,
    },
    component: () => import('@/pages/TermsOfUse.vue'),
  },
  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    name: 'Privacy Policy',
    meta: {
      layout: 'Text',
      requiresAuth: false,
    },
    component: () => import('@/pages/PrivacyPolicy.vue'),
  },
  {
    path: ROUTE_PATHS.CREATE_PASSWORD,
    name: 'Create Password',
    meta: {
      layout: 'Public',
      requiresAuth: false,
    },
    component: () => import('@/pages/CreateOrResetPassword.vue'),
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    name: 'Reset Password',
    meta: {
      layout: 'Public',
      requiresAuth: false,
    },
    component: () => import('@/pages/CreateOrResetPassword.vue'),
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    name: 'Forgot Password',
    meta: {
      layout: 'Public',
      requiresAuth: false,
    },
    component: () => import('@/pages/ForgotPassword.vue'),
  },
]

// Force private routes to not haave requiresAuth set.
// The default is true
type PrivateRoute = AtlasRoute & { meta: { requiresAuth?: null } }

export const helpPageRoutes: PrivateRoute[] = [
  {
    path: ROUTE_PATHS.HELP_SMART_KEYWORDS_GUIDE,
    name: 'Smart Keywords Guide',
    meta: {
      layout: 'Help',
      productUnit: 'procurement',
    },
    component: () => import('@/pages/SmartKeywordsGuide.vue'),
  },

  {
    path: ROUTE_PATHS.HELP_PROCUREMENT_SEARCH,
    name: 'Procurement Search Help',
    meta: {
      layout: 'Help',
    },
    component: () => import('@/pages/help/procurement-search.vue'),
  },
  {
    path: ROUTE_PATHS.HELP_INTENT_DATA_PLAY,
    name: 'Intent Data Play',
    meta: {
      layout: 'Help',
    },
    component: () => import('@/pages/help/intent-data-play.vue'),
  },
  {
    path: ROUTE_PATHS.HELP_COMPETITOR_PLAY,
    name: 'Competitor Research Play',
    meta: {
      layout: 'Help',
    },
    component: () => import('@/pages/help/competitor-play.vue'),
  },
  {
    path: ROUTE_PATHS.HELP_REBID_PLAY,
    name: 'Re-Bid Play',
    meta: {
      layout: 'Help',
    },
    component: () => import('@/pages/help/rebid-play.vue'),
  },
  {
    path: ROUTE_PATHS.HELP_BUYING_PLAY,
    name: 'Buying Committee Play',
    meta: {
      layout: 'Help',
    },
    component: () => import('@/pages/help/buying-play.vue'),
  },
]

const privateRoutes: (PrivateRoute | RedirectRoute)[] = [
  {
    path: ROUTE_PATHS.HOME,
    name: 'Home',
    meta: {
      layout: 'Main',
    },
    // This component will never be shown. We handle the home redirect in beforeEnter
    // This is a hack to make the router work bc route guards can't be used to set the store up before we enter the route.
    // See: https://router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
    // > Note that Navigation Guards are not applied on the route that redirects, only on its target
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: ROUTE_PATHS.LOGOUT,
    name: 'Logout',
    meta: {
      layout: 'Public',
    },
    // Same hack as the home route
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: ROUTE_PATHS.MY_KEYWORDS,
    name: 'My Keywords',
    meta: {
      layout: 'Main',
      productUnit: 'keywords',
      readOnlyFilters: true,
    },
    component: () => import('@/pages/Keywords.vue'),
  },
  {
    path: ROUTE_PATHS.KEYWORD_SEARCH,
    name: 'Keyword Search',
    meta: {
      layout: 'Main',
      productUnit: 'keywords',
      savedFilterKey: 'keyword_search',
    },
    component: () => import('@/pages/Keywords.vue'),
  },
  {
    path: ROUTE_PATHS.MY_ACCOUNTS,
    name: 'My Accounts',
    meta: {
      layout: 'Main',
      productUnit: 'accounts',
      readOnlyFilters: true,
    },
    component: () => import('@/pages/Accounts.vue'),
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SEARCH,
    name: 'Account Search',
    meta: {
      layout: 'Main',
      productUnit: 'accounts',
    },
    component: () => import('@/pages/Accounts.vue'),
  },
  {
    path: ROUTE_PATHS.ACCOUNT_DEEP_DIVE,
    name: 'Account Deep Dive',
    meta: {
      layout: 'Main',
      productUnit: 'accounts',
    },
    component: () => import('@/pages/AccountDeepDive.vue'),
  },
  {
    path: ROUTE_PATHS.ACCOUNTS,
    redirect: ROUTE_PATHS.ACCOUNT_SEARCH,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_KEYWORDS,
    name: 'Campaigns - By Keyword',
    meta: {
      layout: 'Main',
      productUnit: 'campaign',
      savedFilterKey: 'campaign_keywords',
    },
    component: () => import('@/pages/CampaignKeywords.vue'),
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_PERSONAS,
    name: 'Campaigns - By Persona',
    meta: {
      layout: 'Main',
      productUnit: 'campaign',
      savedFilterKey: 'campaign_personas',
    },
    component: () => import('@/pages/CampaignPersonas.vue'),
  },
  {
    path: ROUTE_PATHS.PROCUREMENT_ALERTS,
    name: 'Procurement Alerts',
    meta: {
      layout: 'Main',
      productUnit: 'procurement',
    },
    component: () => import('@/pages/ProcurementAlerts.vue'),
  },
  {
    path: ROUTE_PATHS.PROCUREMENT_OPPORTUNITIES,
    name: 'My Opportunities',
    meta: {
      layout: 'Main',
      productUnit: 'procurement',
      readOnlyFilters: true,
    },
    component: () => import('@/pages/ProcurementOpportunities.vue'),
  },
  {
    path: ROUTE_PATHS.PROCUREMENT_SEARCH,
    name: 'Procurement Search',
    meta: {
      layout: 'Main',
      productUnit: 'procurement',
      savedFilterKey: 'procurement_search',
    },
    component: () => import('@/pages/ProcurementOpportunities.vue'),
  },
  {
    path: ROUTE_PATHS.PROCUREMENT_DETAILS,
    name: 'Procurement Details',
    meta: {
      layout: 'Main',
      productUnit: 'procurement',
    },
    component: () => import('@/pages/ProcurementDetails.vue'),
  },
  {
    path: ROUTE_PATHS.UPSELL,
    name: 'Upsell',
    meta: {
      layout: 'Main',
    },
    component: () => import('@/pages/Upsell.vue'),
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    name: 'Settings',
    meta: {
      layout: 'Main',
    },
    component: () => import('@/pages/Settings.vue'),
  },
  {
    path: ROUTE_PATHS.RFP_PDF,
    name: 'RFP PDF',
    meta: {
      layout: 'Minimal',
    },
    component: () => import('@/pages/RfpPdf.vue'),
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    name: '404 Page Not Found',
    props: {
      error: customErrorMessage(
        'Page Not Found',
        'Please check the URL or try your <a data-test="error-homepage-link" class="underline" href="/">homepage</a>.'
      ),
    },
    meta: {
      layout: 'Main',
    },
    component: () => import('@/components/ErrorFull.vue'),
  },
  ...helpPageRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...publicRoutes, ...privateRoutes],
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash }
    }

    return {
      top: 0,
    }
  },
})

const handleHomeRoute = async () => {
  const store = useStore()

  // get current_value from home_screen configuration
  let homeScreenValue =
    store?.platformAuth?.product?.configurations?.home_screen
      ?.current_value?.[0]

  // check that homeScreenValue is enabled for them
  // if not, we'll ignore it and use the first enabled product they have
  const homeScreenEnabled = store?.enabledProductUnits.find(
    (unit) => unit.key === homeScreenValue
  )

  if (!homeScreenValue || !homeScreenEnabled) {
    const firstEnabledProductUnit = store?.enabledProductUnits?.[0]
    homeScreenValue = firstEnabledProductUnit?.key
  }

  return productUnitToRouteMap[homeScreenValue]
}

router.onError((error) => {
  const store = useStore()
  store.error = getUserErrorMessage(error)
  store.routeLoading = false
  Bugsnag.notify(error)
})

router.beforeEach(async (to, from) => {
  const store = useStore()

  if (to.redirectedFrom && to.fullPath === ROUTE_PATHS.HOME) {
    if (
      to.redirectedFrom.fullPath === ROUTE_PATHS.HOME &&
      from.fullPath === to.fullPath
    ) {
      throw new Error('Redirect loop detected')
    }
  }

  store.routeLoading = true

  // clears global error state
  store.error = ''

  // Update page title
  if (to.name) document.title = String(to.name)

  const { requiresAuth = true, productUnit, layout } = to.meta as AtlasMeta

  store.layoutName = layout

  const authStore = useAuthStore()

  // check cookie and set the tokens
  if (!authStore.initialized) {
    await authStore.initializeAuth()
  }

  const { user, loggedIn } = authStore

  // Grab path from matched property for nested routes, otherwise matching wouldn't work in the if block below
  // i.e. /reset-password/123456 !== /reset-password/:token
  const matchedPath = to.matched[0].path

  if (
    loggedIn &&
    (to.path === ROUTE_PATHS.LOGIN ||
      to.path === ROUTE_PATHS.FORGOT_PASSWORD ||
      matchedPath === ROUTE_PATHS.RESET_PASSWORD ||
      matchedPath === ROUTE_PATHS.CREATE_PASSWORD)
  ) {
    return {
      path: ROUTE_PATHS.HOME,
      query: to.query,
    }
  }

  // let them through
  if (!requiresAuth) return true

  if (to.path === ROUTE_PATHS.LOGOUT) {
    // Don't love this but we have to return a path here :(
    await authStore.logout()
    return ROUTE_PATHS.LOGIN
  }

  if (!loggedIn) {
    // If original path includes UTM parameters, we don't want those following the route to the login page
    // UTM parameters are "re-applied" after login is complete and user gets redirected back to the original page from the auth store login function
    if (to.query?.utm_campaign) {
      return {
        path: ROUTE_PATHS.LOGIN,
        query: {},
      }
    }

    return {
      path: ROUTE_PATHS.LOGIN,
      query: to.query,
    }
  }

  const { platformAuth } = store

  if (isEmpty(platformAuth)) {
    // If an error happens here, the app will show an error and not render the page
    const platformSet = await store.setPlatformAuth()

    if (!platformSet?.success && !store.error) {
      await authStore.logout()
      return ROUTE_PATHS.LOGIN
    }
  }

  // Not sure if this is necessary bc only admins
  // will have a different user compared to the platform response
  // TODO: if this is needed for an admin vs non admin reason, maybe we expand this condition
  if (isEmpty(user)) {
    await authStore.getUserData()
  }
  if (to.path === ROUTE_PATHS.HOME) {
    const homeRoute = await handleHomeRoute()

    return {
      path: homeRoute,
      query: to.query,
    }
  }

  // If user does not have procurement product unit enabled, redirect them away from procurement details page to the RFP PDF
  const procurementUnit = find(store.enabledProductUnits, {
    key: 'procurement',
  })

  if (
    to.path === getProcurementLink(Number(to.params?.id)) &&
    (!procurementUnit || !procurementUnit.enabled)
  ) {
    return {
      path: getPdfLink(String(to.params.id)),
      params: {
        docId: to.params.id,
      },
    }
  }

  // this page is not restricted to any product unit
  if (!productUnit) return true

  const productUnitEnabled = store.checkEnabledProductUnit(productUnit)

  if (productUnitEnabled) return true

  const fullMissingUnit = store.allProductUnits.find(
    (unit) => unit.key === productUnit
  )

  return {
    name: 'Upsell',
    params: {
      slug: fullMissingUnit?.title.toLowerCase(),
    },
  }
})

router.afterEach(async (to) => {
  const store = useStore()
  store.routeLoading = false

  analytics.page({
    name: to.name,
  })

  return true
})

export default router
