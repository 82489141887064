<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useStore } from '@/store/main'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'

import type { ProductUnitKey, ProductUnitTitles } from '@/types/Platform'

import { lowerCase, xor, sortBy } from 'lodash-es'
import { productUnitSubNavMap } from '@/router/index'
import type { NavLink } from '@/router'

import Icon from '@/components/Icon.vue'
import Logo from '@/components/Logo.vue'
import TextLoading from '@/components/TextLoading.vue'
import AddOnFlag from '@/components/AddOnFlag.vue'

import { ROUTE_PATHS, getUpsellLink } from '@/router/index'

const store = useStore()
const authStore = useAuthStore()

const { accountName, userName } = storeToRefs(authStore)

defineProps({
  mobileNavOpen: Boolean,
  toggleSidebar: Function,
  mobile: Boolean,
  loading: Boolean,
})

type NavGroups = {
  [key: string]: {
    title: ProductUnitTitles
    key: ProductUnitKey
    options: NavLink[]
    upsell?: boolean
  }
}

// Doing this bc now that the dropdowns are computed, we can't update the groups directly
const closedDropdownList = ref([] as string[])

const groupIsOpen = (key: string) => {
  return !closedDropdownList.value.includes(key)
}

const unitNavOrder = ['keywords', 'accounts', 'campaign', 'procurement']

const dropdownOptions = computed(() => {
  const result: NavGroups = {}

  store.inactiveProductUnits.forEach((productUnit) => {
    const { key, title } = productUnit
    const subNavLinks = productUnitSubNavMap[key]

    if (subNavLinks) {
      result[productUnit.key] = {
        title,
        key,
        options: subNavLinks,
        upsell: true,
      }
    }
  })

  store.enabledProductUnits.forEach((productUnit) => {
    const { key, title } = productUnit
    const subNavLinks = productUnitSubNavMap[key]

    if (subNavLinks) {
      result[productUnit.key] = {
        title,
        key,
        options: subNavLinks,
      }
    }
  })

  // Create array to sort
  const resultArray = Object.keys(result).map((key) => {
    return result[key]
  })

  // Sort the dropdowns by the order of the unitNavOrder array
  const sortedResults = sortBy(resultArray, (group) => {
    return unitNavOrder.indexOf(group.key)
  })

  return sortedResults
})

const shallowOptions = ref<NavLink[]>([
  {
    text: 'Settings',
    to: ROUTE_PATHS.SETTINGS,
    dataTest: 'nav-settings',
  },
])

const toggleNavGroup = (groupName: string) => {
  // xor "toggles" an array of values from another
  closedDropdownList.value = xor(closedDropdownList.value, [groupName])
}
</script>

<template>
  <div class="container" :class="{ 'container-open': mobileNavOpen }">
    <div class="nav-container">
      <router-link active-class="" class="nav-home" to="/" data-test="nav-logo">
        <Logo class="nav-home__logo" />
      </router-link>
      <div v-if="loading">
        <div v-for="n in 2" :key="n">
          <TextLoading :min="60" :max="80" class="nav--loading" />
          <TextLoading :min="60" :max="80" class="nav--loading" />
          <TextLoading :min="60" :max="80" class="nav--loading" />
          <div class="nav-divider mt-5">
            <hr />
          </div>
        </div>
      </div>
      <div
        v-for="(group, groupName) in dropdownOptions"
        v-else
        :key="group.title"
      >
        <div class="nav">
          <div class="nav-group" @click="toggleNavGroup(String(groupName))">
            <div class="nav-group__icon">
              <Icon v-if="groupIsOpen(String(groupName))" name="expand_more" />
              <Icon v-else name="expand_less" />
            </div>
            <div class="nav-group__group-title">
              <div v-if="group.upsell" class="w-full">
                <a
                  :href="getUpsellLink(group.title)"
                  class="nav-group__group-title"
                >
                  <span
                    data-test="nav-group-title"
                    class="nav-group__title nav-group__title--clickable"
                    >{{ group.title }}</span
                  >
                  <AddOnFlag v-if="group.upsell" />
                </a>
              </div>

              <div v-else data-test="nav-group-title" class="nav-group__title">
                {{ group.title }}
              </div>
            </div>
          </div>
          <div
            v-if="groupIsOpen(String(groupName))"
            :data-test="'nav-group-' + lowerCase(String(groupName))"
          >
            <div
              v-for="option in group.options"
              :key="option.text"
              class="nav__option"
              :class="{ italic: group.upsell }"
              data-test="nav-option"
            >
              <router-link
                :data-test="option.dataTest || null"
                class="nav__option-deep"
                :to="option.to"
                >{{ option.text }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="nav-divider">
          <hr />
        </div>
      </div>

      <div class="nav-shallow">
        <div v-if="loading" class="nav--loading">
          <TextLoading :min="60" :max="80" class="mt-6" />
        </div>
        <div
          v-for="(option, index) in shallowOptions"
          v-else
          :key="index"
          class="nav__option"
        >
          <router-link
            :data-test="option.dataTest || null"
            class="nav__option-shallow"
            :to="option.to"
            >{{ option.text }}</router-link
          >
        </div>
      </div>
    </div>
    <div v-if="mobile" class="nav-shallow">
      <div class="nav-divider">
        <hr />
      </div>
      <div class="nav__option nav__option-shallow nav__option-account">
        {{ userName }}
      </div>
      <div class="nav__option nav__option-shallow nav__option-account italic">
        {{ accountName }}
      </div>
      <router-link
        to="/logout"
        class="nav__option nav__option-shallow nav__option-account nav__option-logout"
        >Logout</router-link
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
hr {
  @apply w-11/12;
}
.container {
  @apply flex justify-between flex-col content-center left-[-100%] shrink-0 bg-ice-grey-light border-r-[1px] w-[215px] z-10;
  height: calc(100% - $nav-height);
  transition: 400ms;
  top: $nav-height;
  position: fixed;

  @include bp('sm') {
    top: 0;
    left: 0;
    overflow: auto;
    height: 100%;
  }
}

.container-open {
  @apply left-0 pt-3 pb-6 z-[110] w-3/4 overflow-scroll;
}

.nav-container {
  @apply text-sm;
}

.nav-header {
  @apply flex bg-white items-center;
}

.nav-home {
  @apply px-6 h-[68px] border-b-[1px] cursor-pointer hidden bg-white;

  @include bp('sm') {
    display: flex;
  }
}

.nav-shallow {
  @apply mt-4;
}

.nav-group {
  @apply flex mb-1.5 items-center text-slate-medium;
}

.nav-group__group-title {
  @apply flex justify-between items-center w-full;
}

.nav-group__title {
  @apply font-bold cursor-pointer;
}

.nav-group__title--clickable {
  @apply italic text-night-blue font-normal;
}

.nav-group__icon {
  @apply cursor-pointer mx-1.5 ml-3 leading-[0.7];

  span {
    font-size: 18px;
  }
}

.nav {
  @apply my-4;
}

.nav-divider {
  @apply flex items-center justify-center flex-col;
}

.nav__option {
  @apply text-night-blue;
}

.nav__option-shallow {
  @apply pl-6 my-2 inline-block;

  &:hover {
    transition: 300ms;
    color: rgb(11, 11, 14);
  }
}

.nav__option-account {
  @apply block text-xs;
}

.nav__option-logout {
  @apply text-ice-grey-medium mt-3;
}

.nav__option-deep {
  @apply pl-9 py-2 inline-block w-full;

  &:hover {
    transition: 300ms;
    color: rgb(11, 11, 14);
  }
}
.router-link-active {
  @apply flex text-white ml-0 py-2 rounded-r-[4px] max-w-[90%];
  background-image: radial-gradient(
    circle at 100% 0,
    #384c55,
    #010101 100%,
    #7d7d7d 100%
  );

  &:hover {
    color: white;
  }
}

.nav--loading {
  @apply ml-4 mt-5;
}
</style>
