import axios, { type AxiosRequestConfig } from 'axios'
import { useAuthStore } from '@/store/auth'
import Bugsnag from '@bugsnag/js'
import { cloneDeep, pick } from 'lodash'

// Config Vendors
axios.defaults.baseURL = String(import.meta.env.VITE_BASE_API_URL)
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-Client-Url': window.location.href,
}

type MetaElement = Element & { content: string }

// Laravel Token
const csrfToken = document.head.querySelector(
  'meta[name="csrf-token"]'
) as MetaElement
if (csrfToken) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content
} else {
  console.error(
    'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token'
  )
}

if (import.meta.env.MODE !== 'production') {
  axios.defaults.headers.common.debug = true
}

type CustomAxiosConfig = AxiosRequestConfig & {
  _retry?: boolean
}

axios.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    const notifyBugsnag = () => {
      Bugsnag.notify(err, function (event) {
        const localErrorCopy = cloneDeep(err)
        const errorRes = localErrorCopy.response

        const resBody = errorRes?.data

        if (resBody && typeof resBody === 'string') {
          try {
            const parsedResBody = JSON.parse(resBody)
            localErrorCopy.response.data = parsedResBody
          } catch (e) {
            // something happened while parsing. Abandon ship
          }
        }

        const reqBody = errorRes?.config?.data

        if (reqBody && typeof reqBody === 'string') {
          try {
            const parsedReqBody = JSON.parse(reqBody)
            localErrorCopy.request = parsedReqBody
          } catch (e) {
            // something happened while parsing. Abandon ship
          }
        }

        // remove config.data, duplicate of request
        if (localErrorCopy?.config?.data) delete localErrorCopy.config.data

        // remove response.config, duplicate of config
        if (localErrorCopy?.response?.config)
          delete localErrorCopy.response.config

        event.addMetadata(
          'axios',
          pick(localErrorCopy, ['request', 'response', 'code', 'config'])
        )
      })
    }

    // We don't want to report 401s to Bugsnag because it will be noisy.
    if (err.response.status !== 401) {
      notifyBugsnag()
    }

    if (
      originalConfig.url !== '/login' &&
      originalConfig.url !== '/refresh' &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        try {
          // update token via store
          const authStore = useAuthStore()
          const refreshed = await authStore.refresh()

          if (!refreshed) {
            // unable to refresh. don't retry
            return Promise.reject(err)
          }

          originalConfig._retry = true
          return axios(originalConfig)
        } catch (_error) {
          console.error(_error)
          // return Promise.reject(_error)
          throw _error
        }
      }
    }

    return Promise.reject(err)
  }
)

axios.interceptors.request.use(
  (config: CustomAxiosConfig) => {
    const authStore = useAuthStore()

    const token = authStore.getCookieToken()
    if (token) {
      // return if authorization is already set
      if (!config.headers) {
        config.headers = {}
      }

      // If we are retrying the request, let the override happen.
      // The previous config likely had an expired token.
      if (!config?.headers?.['Authorization'] || config._retry) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
