<script setup lang="ts">
import { defineProps, computed } from 'vue'

const props = defineProps({
  modifier: String,
  isHtml: Boolean,
})

const modifierStyle = computed(() => {
  if (props.modifier) {
    return 'card--' + props.modifier
  }

  return ''
})
</script>

<template>
  <div :class="[{ card: !isHtml }, modifierStyle]">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.card {
  @apply bg-white px-4 py-4 rounded border border-ice-grey-medium-light;

  @include bp('sm') {
    @apply p-6;
  }
}

.card--two-rows {
  grid-row: span 2 / span 2;
}
</style>
