<script setup lang="ts">
import { computed } from 'vue'
import type { PropType } from 'vue'

import { icons } from '@/icon-list'

/*
 * Generated this @ https://fonts.google.com/icons
 * With:
 * ```
 * document.querySelectorAll('.icon-asset [_ngcontent-mdq-c146]').forEach( function(item){
 *    console.log(item.innerText)
 * });
 * ```
 *
 * If full list is not generated by the above, grab from raw data: https://raw.githubusercontent.com/google/material-design-icons/master/font/MaterialIcons-Regular.codepoints
 */

// convert namesArr into string literal union type
export type IconName = typeof icons[number] // eg. "search" | "home" | "menu"

const props = defineProps({
  name: {
    type: String as PropType<IconName>,
    required: true,
  },
  filled: Boolean,
})

const iconExists = computed(() => {
  return icons.includes(props.name)
})

const filledStyle = computed(() => {
  return "font-variation-settings: 'FILL' 1"
})
</script>

<template>
  <span
    v-if="iconExists"
    class="material-symbols-outlined"
    :style="filled ? filledStyle : ''"
  >
    {{ props.name }}
  </span>
</template>
