import { onMounted, onUnmounted, ref, computed } from 'vue'
import { BREAKPOINTS } from '@/constants'

export function mobileViewport() {
  const viewport = ref<null | number>(null)
  function setViewportSize() {
    if (window) {
      viewport.value = window.innerWidth
    }
  }

  const mobile = computed(() => {
    if (viewport.value) {
      return Boolean(BREAKPOINTS.sm > viewport.value)
    }
    return false
  })

  onMounted(() => {
    setViewportSize()
    window.addEventListener('resize', setViewportSize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', setViewportSize)
  })

  return { mobile }
}
