import type { AxiosResponse } from 'axios'

import { supportEmailLink } from '@/constants.js'

export function customErrorMessage(title: string, message: string) {
  return `
    <p class="user-error-message font-bold">${title}</p>
    <p>${message}</p>
  `
}

export const filtersError = `<p class="user-error-message"><strong>We're experiencing issues loading the page.</strong></p><p>Please try reloading. Use the feedback tab if the issue persists.</p>`

export default function getUserErrorMessage(
  res: AxiosResponse<unknown> | null
) {
  // default error message - 4xx & 5xx errors
  let errorMessage = customErrorMessage(
    `We're experiencing issues loading the page.`,
    `Please contact ${supportEmailLink} for support.`
  )

  if (res) {
    if (res.status === 404) {
      errorMessage = customErrorMessage(
        `Content not found.`,
        `Check that current the browser address is correct and try again. Use the feedback tab if the issue persists.`
      )
    }
    if (res.status === 401) {
      errorMessage = customErrorMessage(
        `Your login token has expired.`,
        `Please reload and try again. If the issue persists, contact ${supportEmailLink} for assistance.`
      )
    }
  }

  return errorMessage
}
