<script setup lang="ts">
import { computed } from 'vue'

import track from '@/services/track'

import { helpPageRoutes } from '@/router/index'

import { encodeMailto } from '@/utils/encodeMailto'

import SupportEmail from '@/components/SupportEmail.vue'
import Icon from '@/components/Icon.vue'
import { snakeCase } from 'lodash-es'

const mailto = encodeMailto({
  subject: 'Assistance with MarketEdge',
  body: 'I need assistance with the MarketEdge.',
})

const videoLinks = computed(() => {
  return [
    {
      title: 'Procurement Alerts',
      link: 'https://share.synthesia.io/b7535314-e832-4966-b795-2f8edab196a3',
      key: 'video_procurement_alerts',
    },
    {
      title: 'Procurement Search',
      link: 'https://share.synthesia.io/c3ebc3b4-43d0-4f30-b406-c2c26a5eea06',
      key: 'video_procurement_search',
    },
    {
      title: 'My Opportunities',
      link: 'https://share.synthesia.io/685444f3-5c52-4ead-8bdf-f97aa46428d1',
      key: 'video_my_opportunities',
    },
    {
      title: 'Account Search',
      link: 'https://share.synthesia.io/9d023321-410a-48b9-a254-1e4da4d2dcb4',
      key: 'video_account_search',
    },
    {
      title: 'Ideal Customer Profile (ICP)',
      link: 'https://share.synthesia.io/9f7c5d81-5296-44aa-baa3-42917d138646',
      key: 'video_icp',
    },
  ]
})

const helpLinks = computed(() => {
  return helpPageRoutes.map((route) => {
    return {
      title: route.name,
      link: route.path,
      key: snakeCase(route.name?.toString()),
    }
  })
})
</script>

<template>
  <p class="title">Need Help?</p>
  <p class="mb-4 text-sm">
    The customer success team is standing by. Email
    <SupportEmail :mail-to="mailto" />.
  </p>

  <div class="text-sm">
    <p class="font-bold mb-1.5">Learning resources</p>

    <!-- loop through help links -->
    <router-link
      v-for="link in helpLinks"
      :key="link.key"
      class="link"
      :to="link.link"
      data-test="help-link"
      @click="track.trackHelpResourceClick(link.key)"
      ><Icon name="article" /><span class="ml-2 underline">{{
        link.title
      }}</span></router-link
    >

    <a
      v-for="video in videoLinks"
      :key="video.key"
      class="link"
      target="_blank"
      :href="video.link"
      @click="track.trackHelpResourceClick('video_series')"
      ><Icon name="play_circle" /><span class="ml-2 underline">{{
        video.title
      }}</span></a
    >
  </div>
</template>

<style lang="scss" scoped>
.title {
  @apply font-bold mb-3 text-lg;
}

.link {
  @apply flex-align-center block mb-1.5;
}
</style>
