<script setup lang="ts">
import { defineProps, computed } from 'vue'

const props = defineProps({
  max: {
    required: false,
    type: Number,
  },
  min: {
    required: false,
    type: Number,
  },
})

const randomizedWidth = computed(() => {
  if (props.min && props.max) {
    const minNum = Math.ceil(props.min)
    const maxNum = Math.floor(props.max)
    const num = Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum
    return num + '%'
  }
  return ''
})
</script>

<template>
  <div
    :style="{
      width: randomizedWidth,
    }"
    class="loading-text"
  />
</template>

<style scoped lang="scss">
.loading-text {
  @apply bg-light-grey p-2;
  border-radius: 2px;
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.3;
  }
}
</style>
