<template>
  <div class="nav-group__flag">ADD-ON</div>
</template>

<style scoped lang="scss">
.nav-group__flag {
  @apply text-[#46cd68] px-3 mr-3 uppercase font-bold;
  font-size: 9px;
  line-height: 1.6;
  background: rgba(70, 205, 104, 0.2);
  border-radius: 7px;
}
</style>
