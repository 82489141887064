<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import { computed } from 'vue'
import type { Component } from 'vue'
import { useStore } from '@/store/main'
import { storeToRefs } from 'pinia'

import LoadingCard from '@/components/LoadingCard.vue'
import ErrorFull from '@/components/ErrorFull.vue'
import Text from '@/layouts/text.vue'
import Public from '@/layouts/public.vue'
import Main from '@/layouts/main.vue'
import Minimal from '@/layouts/minimal.vue'
import Help from '@/layouts/help.vue'

const store = useStore()
const { routeLoading, error } = storeToRefs(store)

const layout = computed<null | Component>(() => {
  if (!store.layoutName) return Main
  if (store.layoutName === 'Public') return Public
  if (store.layoutName === 'Main') return Main
  if (store.layoutName === 'Text') return Text
  if (store.layoutName === 'Minimal') return Minimal
  if (store.layoutName === 'Help') return Help
  return null
})
</script>

<template>
  <component :is="layout">
    <ErrorFull v-if="error" :error="error" />
    <LoadingCard v-else-if="routeLoading" :height="400" />
    <router-view v-else />
  </component>
</template>

<style lang="scss">
#app {
  @apply font-sans text-grey-800 antialiased;
  min-height: 100vh;
}
</style>
