import { supportEmail } from '@/constants'

export function encodeMailto({
  subject = '',
  body = '',
  email = supportEmail,
}) {
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)

  return `mailto:${email}${subject ? '?subject=' + encodedSubject : ''}${
    body ? '&body=' + encodedBody : ''
  }`
}
