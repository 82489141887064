<template>
  <div class="footer">
    <div class="footer__links">
      <router-link to="/privacy-policy">Privacy Policy</router-link>
      <router-link to="/terms-of-use">Terms of Use</router-link>
    </div>
    <div class="footer__content">
      <p>Copyright © {{ new Date().getFullYear() }} MarketEdge, The Atlas</p>
      <p>All Rights Reserved</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer__links {
  text-decoration: underline;

  a + a {
    @apply ml-3;
  }
}

.footer {
  @apply flex p-9 items-center flex-col text-slate-medium;
  font-size: 10px;
}

.footer__content {
  @apply mt-1.5 flex flex-col items-center;
}
</style>
