import Bugsnag from '@bugsnag/js'
import axios, { type AxiosResponse, type AxiosRequestConfig } from 'axios'
import getUserErrorMessage from '@/utils/getUserErrorMessage'
import { useStore } from '@/store/main'
import { isEmpty } from 'lodash-es'

type MinimumErrorResponse = {
  errors?: object
}

export async function asyncRequest<
  T,
  ErrorResponse extends MinimumErrorResponse = MinimumErrorResponse
>(
  axiosConfig: AxiosRequestConfig<unknown>,
  isMainRequest: boolean | null = null
) {
  const store = useStore()
  let res: AxiosResponse<T> | null = null
  let errorMessage = ''
  let errorResponse: AxiosResponse<ErrorResponse> | null = null

  const axiosOptions: AxiosRequestConfig = {
    ...axiosConfig,
    ...(axiosConfig.headers && { headers: axiosConfig.headers }),
    method: axiosConfig.method || 'post',
  }

  try {
    res = await axios(axiosOptions)
  } catch (e) {
    const feedbackTabMessage =
      'Please use the feedback tab to report the issue if it persists.'

    if (axios.isAxiosError(e) && e.response) {
      const { data } = axiosConfig

      // if isMainRequest equals false, it means we are passing in false and it should be respected rather than treated as falsy
      if (isMainRequest !== false) {
        // if isMainRequest is not false, evaluate it was true or falsy
        if (isEmpty(data) || isMainRequest) {
          // if there is no data, this is a top level page error
          errorMessage = getUserErrorMessage(e.response)
          store.error = errorMessage
        }
      }

      if (!store.error) {
        errorMessage = `<strong>Unable to load data.</strong><br>Try to reload the page. ${feedbackTabMessage}`
      }

      const response = e?.response as AxiosResponse<ErrorResponse>
      errorResponse = response
    } else {
      // Only notify Bugsnag of non-axios errors here
      // Axios errors are reported to Bugsnag in axios response interceptor
      Bugsnag.notify(e as Error)
    }
  }

  return { res, errorMessage, errorResponse }
}
