import { defineStore } from 'pinia'
import type {
  PlatformAuth,
  PopulationOption,
  AccountOption,
  StateOption,
  UnitAuthKeywords,
  UnitAuthAccounts,
  UnitAuthProcurement,
  UnitAuthCampaign,
  KeywordOption,
  DateRangeOption,
  SavedAlert,
  AppliedFilters,
  PersonaOption,
  EmailSubscription,
  ProcurementTypeOption,
  JurisdictionOption,
  BusinessSegmentOption,
  ProductUnitListItem,
} from '@/types/Platform'
import type {
  Account,
  AccountFilterKeys,
  AppliedAccountsFilters,
} from '@/types/Accounts'
import type { AtlasResponse } from '@/types/Helpers'

import { isEmpty, find, isEqual, get, xorBy } from 'lodash-es'
import axios from '@/utils/axios'
import type { AxiosResponse } from 'axios'
import Bugsnag from '@bugsnag/js'
import { asyncRequest } from '@/composables/asyncRequest'
import type {
  SearchValues,
  SavedSearch,
  ProcurementStoreFilters,
} from '@/types/Procurement'

type UsedProductUnitKeys = keyof ProductUnits

export type FilterEmptyStates = {
  type: ProcurementTypeOption
  jurisdiction: JurisdictionOption
  accounts: AccountOption[]
  keywords: KeywordOption[]
  states: StateOption[]
  population: PopulationOption
  date_range: DateRangeOption
  segments: BusinessSegmentOption[]
  search_query: string
  contains_query: string
  excludes_query: string
}

export type AdvancedFilterKeys =
  | 'excludes_query'
  | 'contains_query'
  | 'search_query'
  | 'accounts'
  | 'date_range'
  | 'segments'
  | 'jurisdiction'
  | 'type'

export type StoreFilterNames = keyof ReturnType<typeof useStore>['savedFilters']

export type ProductUnits = {
  keywords: UnitAuthKeywords | null
  accounts: UnitAuthAccounts | null
  campaign: UnitAuthCampaign | null
  procurement: UnitAuthProcurement | null
  // Add these types once we have them
  // content?: unknown
}

export type ProductUnitEndpoints = NonNullable<
  ProductUnits[UsedProductUnitKeys]
>['endpoints']

export type ProductUnitEndpointKeys = Keys<ProductUnitEndpoints>
// export type ProductUnitEndpointKeys = keyof ProductUnitEndpoints

type PlatformAuthResponse = AtlasResponse<'platform_auth', PlatformAuth>
type UnitAuthResponse<T extends UsedProductUnitKeys> = AtlasResponse<
  'unit_auth',
  ProductUnits[T]
>

export type StoreFilters = Partial<AppliedFilters> & SearchValues

export type KeywordStoreFilters = Partial<
  Omit<AppliedFilters, 'states' | 'accounts'>
>

export type AccountStoreFilters = AppliedFilters

export type PersonaStoreFilters = Pick<StoreFilters, 'personas' | 'population'>

export const useStore = defineStore('main', {
  state: () => {
    return {
      // Note - because we do not have the full object at startup, nested changes are not reactive!
      // This shouldn't be an issue because we don't refetch the platform auth after startup.
      platformAuth: {} as PlatformAuth,
      // Same applies here
      unitAuth: {
        keywords: null,
        campaign: null,
        procurement: null,
      } as ProductUnits,
      layoutName: '',
      routeLoading: true,
      unitAuthLoading: false,
      currentAccount: {} as Account,
      error: '', // used to display top level 5xx and 4xx page errors
      savedFilters: {
        keyword_search: {} as KeywordStoreFilters,
        campaign_keywords: {} as KeywordStoreFilters,
        campaign_personas: {} as PersonaStoreFilters,
        procurement_search: {} as ProcurementStoreFilters,
      },
      currentlySetAccountFilters: {} as AppliedAccountsFilters,
      accountFilterType: '' as AccountFilterKeys,
    }
  },
  actions: {
    async setPlatformAuth() {
      try {
        const { res, errorMessage } = await asyncRequest<PlatformAuthResponse>({
          url: '/proxy/platform/auth/marketedge',
          method: 'post',
        })

        if (errorMessage) throw new Error('Failed to get platform auth')
        if (res) this.platformAuth = res.data.platform_auth
        return {
          success: true,
        }
      } catch (e) {
        Bugsnag.notify(e as Error)
        return {
          success: false,
        }
      }
    },
    async initUnitAuth(unitAuthName: UsedProductUnitKeys, force = false) {
      if (force || !this.unitAuth[unitAuthName]) {
        await this.setUnitAuth(unitAuthName)
      }
    },
    async setUnitAuth(unit: UsedProductUnitKeys) {
      this.unitAuthLoading = true
      try {
        const result: AxiosResponse<UnitAuthResponse<typeof unit>> =
          await axios.post(`/proxy/platform/auth/marketedge/${unit}`)

        if (!result.data.success) throw new Error('Failed to get unit auth')

        Object.assign(this.unitAuth, {
          [unit]: result.data.unit_auth,
        })

        return {
          success: true,
        }
      } catch (e) {
        Bugsnag.notify(e as Error)
        return {
          success: false,
        }
      } finally {
        this.unitAuthLoading = false
      }
    },
    getDefaultDate(unitAuthType: UsedProductUnitKeys): DateRangeOption | null {
      /*
      API options may come back with display values that differ from the display value in the default.
      What comes back from the options is the source of truth.
      */
      const defaultDate =
        this.unitAuth?.[unitAuthType]?.configurations?.default_date_range
          ?.current_value

      const options = this.getPlatformDateRangeOptions(unitAuthType)

      const fullDisplayObject = find(options, (option) => {
        return isEqual(option.value, defaultDate?.value)
      })

      return fullDisplayObject || null
    },
    getPlatformDateRangeOptions(
      unitAuthType: UsedProductUnitKeys
    ): DateRangeOption[] {
      return (
        this.unitAuth?.[unitAuthType]?.configurations?.default_date_range
          ?.options || []
      )
    },
  },
  getters: {
    enabledProductUnits(state) {
      // get enabled units from platformAuth.product.units
      if (isEmpty(state.platformAuth)) return []

      return state.platformAuth?.product?.units?.filter((unit) => unit.enabled)
    },
    allProductUnits(): ProductUnitListItem[] {
      return [
        // {
        //   key: 'content',
        //   title: 'Content',
        //   enabled: 0,
        // },
        {
          key: 'keywords',
          title: 'Pipeline',
          enabled: 1,
        },
        {
          key: 'accounts',
          title: 'Sales',
          enabled: 1,
        },
        {
          key: 'campaign',
          title: 'Campaigns',
          enabled: 1,
        },
        {
          key: 'procurement',
          title: 'Procurement',
          enabled: 1,
        },
      ]
    },
    inactiveProductUnits(state): ProductUnitListItem[] {
      // get enabled units from platformAuth.product.units
      if (isEmpty(state.platformAuth)) return []

      // If a unit comes back but has enabled set to 0, it means it's disabled for everyone and should never show for anyone ever
      // If a unit is totally missing, that means it's inactive for this user and an upsell flow should be available for that unit
      return xorBy(this.enabledProductUnits, this.allProductUnits, 'key')
    },
    checkEnabledProductUnit() {
      return (unitKey: string): boolean => {
        const enabledProductUnits = this.enabledProductUnits
        return enabledProductUnits.some((unit) => unit.key === unitKey)
      }
    },
    platformPopulationOptions(state): PopulationOption[] {
      return (
        state.platformAuth?.product?.configurations?.target_account_population
          .options || []
      )
    },
    procurementTypeOptions(): ProcurementTypeOption[] {
      return [
        {
          display: 'Pre-procurement',
          value: 'pre-procurement',
        },
        {
          display: 'Procurement',
          value: 'procurement',
        },
        {
          display: 'All Stages',
          value: 'all',
        },
      ]
    },
    jurisdictionOptions(): JurisdictionOption[] {
      return [
        {
          display: 'State',
          value: 'state',
        },
        {
          display: 'Local',
          value: 'local',
        },
        {
          display: 'All Jurisdiction Types',
          value: 'all',
        },
      ]
    },
    defaultAccounts(state): AccountOption[] {
      return (
        state.platformAuth?.product?.configurations?.target_accounts
          ?.current_value || []
      )
    },
    defaultStates(state): StateOption[] {
      return (
        state.platformAuth?.product?.configurations?.target_states
          ?.current_value || []
      )
    },
    defaultPopulation(state): PopulationOption | null {
      /*
      API options may come back with display values that differ from the display value in the default.
      What comes back from the options is the source of truth.
      */

      const defaultPopulation =
        state.platformAuth?.product?.configurations?.target_account_population
          ?.current_value

      const fullDisplayObject = find(
        this.platformPopulationOptions,
        (option) => {
          return isEqual(option.value, defaultPopulation?.value)
        }
      )

      return fullDisplayObject || null
    },
    defaultKeywords(state): KeywordOption[] {
      return (
        state.platformAuth?.product?.configurations?.target_keywords
          ?.current_value || []
      )
    },
    defaultPersonas(state): PersonaOption[] {
      return (
        state.platformAuth?.product?.configurations?.target_personas
          ?.current_value || []
      )
    },
    emailSubscriptions(state): EmailSubscription[] {
      const subscriptionsObject = state.platformAuth?.product?.subscriptions

      if (!subscriptionsObject) return []

      // get values and flatten the array
      let subscriptions = Object.values(subscriptionsObject).flat()

      // filter out account_deep_dive since this email is not live yet
      subscriptions = subscriptions.filter(
        (subscription) => subscription.value !== 'account_deep_dive'
      )

      // filter out any subscriptions that have status = false
      return subscriptions.filter((subscription) => subscription.status)
    },
    savedAlerts(state): SavedAlert[] {
      return get(
        state,
        'unitAuth.procurement.configurations.saved_alerts.current_value',
        []
      )
    },
    savedOpportunities(state): string[] {
      return get(
        state,
        'unitAuth.procurement.configurations.saved_opportunities.current_value',
        []
      )
    },
    savedSearches(state): SavedSearch[] {
      return get(
        state,
        'unitAuth.procurement.configurations.saved_oppsearches.current_value',
        []
      )
    },
    advancedFilterKeys(): AdvancedFilterKeys[] {
      return [
        'excludes_query',
        'accounts',
        'date_range',
        'jurisdiction',
        'segments',
        'type',
      ]
    },
    // Ultimate fallbacks for filters when default filters either aren't available or shouldn't be used
    // These are the most broad options pulled from the API options

    //
    broadFilterState(): FilterEmptyStates {
      return {
        accounts: [
          {
            display: 'All Accounts',
            value: 'all',
            account_type: 'City',
          },
        ],
        jurisdiction: {
          display: 'All Jurisdiction Types',
          value: 'all',
        },
        type: {
          display: 'All Stages',
          value: 'all',
        },
        keywords: [
          {
            display: 'All Keywords',
            value: 'all',
          },
        ],
        states: [
          {
            display: 'All States/National',
            value: 'national',
          },
        ],
        population: {
          display: 'All Population Sizes',
          value: {
            max: -1,
            min: 0,
          },
        },
        date_range: {
          display: 'Last 12 Months',
          value: 'subMonths:12',
        },
        segments: [
          {
            display: 'All Business Segments',
            value: 'all',
          },
        ],
        search_query: '',
        excludes_query: '',
        contains_query: '',
      }
    },
  },
})
