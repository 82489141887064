<template>
  <div class="spinner"></div>
</template>

<style scoped lang="scss">
.spinner {
  min-width: 30px;
}
.spinner::before {
  content: '';
  animation: 1.5s linear infinite spinner;
  border: solid 2px var(--ice-grey-medium-light);
  border-bottom-color: var(--night-blue);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
</style>
