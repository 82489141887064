<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import Logo from '@/components/Logo.vue'
import Icon from '@/components/Icon.vue'
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useStore } from '@/store/main'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { mobileViewport } from '@/composables/mobileViewport'

// Store setup
const store = useStore()

const { routeLoading } = storeToRefs(store)

// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
// On iOS devices, there is a problem where when there is an overlayed scrollable element, the scroll behavior targets the body.
// JS from link above solves this.
const showDialog = () => {
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
  const body = document.body
  body.style.position = 'fixed'
  body.style.top = `-${scrollY}`

  const html = document.getElementsByTagName('html')[0]
  html.style.height = '100vh'
}

const closeDialog = () => {
  const body = document.body
  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''

  const html = document.getElementsByTagName('html')[0]
  html.style.height = ''

  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

const mobileNavOpen = ref(false)
const toggleSidebar = () => {
  mobileNavOpen.value = !mobileNavOpen.value

  if (mobileNavOpen.value) {
    showDialog()
  }

  if (!mobileNavOpen.value) {
    closeDialog()
  }

  document.body.classList.toggle('disable-scroll')
}

const { mobile } = mobileViewport()

const router = useRouter()

router.beforeResolve(() => {
  if (mobile.value && mobileNavOpen.value) {
    toggleSidebar()
  }
})

const iosScrollListener = () => {
  document.documentElement.style.setProperty(
    '--scroll-y',
    `${window.scrollY}px`
  )
}

onMounted(() => {
  if (mobile.value) {
    window.addEventListener('scroll', iosScrollListener)
  }
})

onUnmounted(() => {
  if (mobile.value) {
    window.removeEventListener('scroll', iosScrollListener)
  }
})
</script>

<template>
  <div class="main-container" :class="{ 'flex flex-col w-[100vw]': mobile }">
    <Nav
      :mobile="mobile"
      :mobile-nav-open="mobileNavOpen"
      :loading="routeLoading"
      :toggle-sidebar="toggleSidebar"
    />
    <div
      v-if="mobile"
      class="header"
      :class="{ 'header--borderless': mobileNavOpen }"
    >
      <div class="header-mobile">
        <Icon
          class="mobile-toggle"
          :name="mobileNavOpen ? 'close' : 'menu'"
          @click="toggleSidebar"
        />
        <router-link class="mobile-logo" to="/">
          <Logo />
        </router-link>
      </div>
    </div>
    <div class="main-container__content">
      <div class="main-container__header">
        <Breadcrumb :mobile="mobile" :loading="routeLoading" />
      </div>
      <div class="main-container__slot">
        <div
          :class="{ 'main-container__nav-wrapper': mobileNavOpen }"
          @click="toggleSidebar"
        />
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-container {
  @apply flex;
  min-height: 100vh;
}

.main-container__content {
  @apply w-[100%] ml-0;

  @include bp('sm') {
    margin-left: 215px;
  }
}

.breadcrumb {
  @apply text-2xl;
}

.main-container__slot {
  @apply pt-9 px-3 max-w-[1219px];
  min-height: 100vh;
  margin: 0 auto;

  @include bp('sm') {
    @apply px-12 pt-12;
  }
}

.main-container__nav-wrapper {
  @apply fixed w-[100%] h-[100%] overflow-hidden left-0 absolute z-[100];
  transition: 400ms background-color;
  background-color: rgba(0, 0, 0, 0.531);
  top: $nav-height;
  touch-action: none;
  position: fixed;
}

.header {
  @apply flex items-center z-[150] max-w-[1219px] px-3 justify-center border-b-[1px] border-light-grey bg-ice-grey-light;
  height: $nav-height;
  position: sticky;
  top: 0;
}

.header--sticky {
  position: sticky;
  top: 0;
}

.mobile-logo {
  @apply w-[130px];
}

.header-mobile {
  @apply flex justify-between grow;
}

.header-mobile::after {
  flex: 1;
  content: '';
}

.mobile-toggle {
  @apply cursor-pointer;
  flex: 1;
}
</style>
