import { isEmpty, keys, snakeCase } from 'lodash-es'

import type {
  SettingsKey,
  SettingsPayload,
  CampaignPayload,
  AccountsPayload,
} from '@/types/PageSpecificDeclarations'

import type { AppliedKeywordFilters } from '@/types/Keywords'
import type { OpportunitiesRequestBody } from '@/types/Procurement'

// Multi-use tracking function
function fireTracking<Data>(eventName: string, data: Data | null = null) {
  if (data) {
    analytics.track(eventName, data)
  } else {
    analytics.track(eventName)
  }

  // Send Hotjar Event
  // https://help.hotjar.com/hc/en-us/articles/4405109971095
  // To confirm that this works, enable debug logs by adding ?hjDebug=1 to the page URL
  if (window.hj && eventName) {
    // Hotjar events can only have a name, and it cannot contain spaces.
    // We can take the event name we have for Segment and easily make it HJ friendly.
    // E.g. 'Projects Searched' => 'projects_searched'
    window.hj('event', snakeCase(eventName))
  }
}

/**
 * More specific functions that are reused in multiple files.
 * Instead of schlepping around typescript types to each component where tracking would be called,
 * it is easier to keep it all consolidated here.
 */

type FiltersPayload =
  | AppliedKeywordFilters
  | AccountsPayload
  | CampaignPayload
  | OpportunitiesRequestBody

type FiltersTrackedData = {
  default: boolean
  filtered_by: string[]
  filters: FiltersPayload
  result_count: number
}

type DeepDiveLinkData = {
  account_slug: string
}

type ExportSections =
  | 'keywords'
  | 'contacts'
  | 'campaign_keywords'
  | 'campaign_personas'
  | 'procurement_search'
  | 'saved_opportunities'
  | 'my_opportunities'

type ExportData = {
  section: ExportSections
  filtered_by?: string[]
  filters?: FiltersPayload
}

type LogoutTrackingData = {
  user_initiated: boolean
}

type AccordionClickData = {
  type: string
  list_position: number
}

type AlertSaveData = {
  alert: string
  filters: FiltersPayload
  isNew: boolean
}

type RFPTrackData = {
  rfp_id: string | number
}

type ProcurementTrackData = {
  procurement_id: string
}

type SettingsData = {
  setting_type: SettingsKey
  setting_value: SettingsPayload
}

const getFilterProperties = (appliedFilters: FiltersPayload) => {
  const filteredBy = keys(appliedFilters)

  return {
    filtered_by: filteredBy,
    filters: appliedFilters,
  }
}

const track = {
  // Export Account Deep Dive Contacts or Keywords
  trackExport: (
    sectionName: ExportSections,
    appliedFilters?: FiltersPayload | null
  ) => {
    const filterProperties = appliedFilters
      ? getFilterProperties(appliedFilters)
      : {}
    fireTracking<ExportData>('Export Clicked', {
      section: sectionName,
      ...filterProperties,
    })
  },
  // Deep Dive Link clicked, used on Keywords & Accounts pages
  trackDeepDiveClick: (slug: string, scrollTo = '') => {
    type Payload = {
      account_slug: string
      scroll_to?: string
    }

    let data: Payload = {
      account_slug: slug,
    }

    if (scrollTo) {
      data = {
        ...data,
        scroll_to: scrollTo,
      }
    }

    fireTracking<DeepDiveLinkData>('Account Deep Dive Link Clicked', data)
  },
  trackFilters: (
    type: string,
    payload: FiltersPayload,
    appliedFilters: FiltersPayload,
    results: unknown[],
    options?: {
      resultCount?: number
    }
  ) => {
    const isDefault = isEmpty(payload) // returns true or false

    const filterProperties = getFilterProperties(appliedFilters)

    fireTracking<FiltersTrackedData>(type + ' Searched', {
      default: isDefault,
      result_count: options?.resultCount || results?.length,
      ...filterProperties,
    })
  },
  trackLogin: () => {
    fireTracking('User Logged In')
  },
  trackUserAccessed: () => {
    fireTracking('User Accessed')
  },
  trackLogout: (userInitiated: boolean) => {
    fireTracking<LogoutTrackingData>('User Logged Out', {
      user_initiated: userInitiated,
    })
  },
  trackAccordionClick: (page: string, index: number) => {
    fireTracking<AccordionClickData>('Accordion Clicked Open', {
      type: page,
      list_position: index,
    })
  },
  trackRFPClick: (id: string | number) => {
    fireTracking<RFPTrackData>('RFP Link Clicked', {
      rfp_id: id,
    })
  },
  trackProcurementLinkCopy: (id: string) => {
    fireTracking<ProcurementTrackData>('Procurement Link Copied', {
      procurement_id: id,
    })
  },
  trackSettingsUpdate: (key: SettingsKey, payload: SettingsPayload) => {
    fireTracking<SettingsData>('Settings Updated', {
      setting_type: key,
      setting_value: payload,
    })
  },
  trackConsultationClick: (appliedFilters: FiltersPayload) => {
    const filters = getFilterProperties(appliedFilters)

    fireTracking('Request Consultation Link Clicked', filters)
  },
  trackAlertSave: (data: AlertSaveData) => {
    const { alert, filters, isNew } = data
    fireTracking('Procurement Alert Saved', {
      alert_slug: alert,
      filters,
      new: isNew,
    })
  },
  trackAlertDelete: (alert: string) => {
    fireTracking('Procurement Alert Deleted', {
      alert_slug: alert,
    })
  },
  trackHelpResourceClick: (resource: string) => {
    fireTracking('Help Resource Clicked', {
      resource,
    })
  },
  trackSupportEmailClicked: () => {
    fireTracking('Support Email Clicked')
  },
  trackProcurementSearchPaged: (
    isDefault: boolean,
    filters: FiltersPayload,
    resultCount: number,
    page: number
  ) => {
    fireTracking('Procurement Search Paged', {
      default: isDefault,
      filters,
      result_count: resultCount,
      page,
    })
  },
  trackProcurementSaved: (id: string) => {
    fireTracking('Procurement Saved', {
      procurement_id: id,
    })
  },
  trackProcurementUnsaved: (id: string) => {
    fireTracking('Procurement Unsaved', {
      procurement_id: id,
    })
  },
  trackSearchSaved: (slug: string) => {
    fireTracking('Search Saved', {
      search_slug: slug,
    })
  },
  trackSearchDeleted: (slug: string) => {
    fireTracking('Search Deleted', {
      search_slug: slug,
    })
  },
  trackUpgradeClick: (copy: object) => {
    fireTracking('Upgrade CTA Clicked', copy)
  },
  trackFilterClickedOff: () => {
    fireTracking('Filter Clicked Off')
  },
  trackUpgradeRequest: () => {
    fireTracking('Upgrade Requested')
  },
  trackUpgradeInfoLinkClick: () => {
    fireTracking('Upgrade Info Link Clicked')
  },
}

export default track
