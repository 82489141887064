/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Vue & App
// CSS
import './app.scss'

import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'

// Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

// Click outside
import vClickOutside from 'click-outside-vue3'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginVue()],
  appType: 'client',
  environment: import.meta.env.VITE_BUGSNAG_ENV,
  appVersion: import.meta.env.VITE_BUGSNAG_VERSION,
  enabledReleaseStages: ['production', 'development', 'staging', 'local'],
  releaseStage: import.meta.env.VITE_BUGSNAG_ENV,
  redactedKeys: [
    'access_token', // exact match: "access_token"
    /password/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd". Will also catch c_password usages
    /^cc_/, // prefix match: "cc_number" "cc_cvv" "cc_expiry"
    // /Authorization/i, // disable this for better reporting
  ],
})

const bugsnagVue = Bugsnag.getPlugin('vue')

import router from './router/index.ts'

// Global vee-validate config. Move rules in here if they are used in multiple components, otherwise import directly into component where used.
import { configure, defineRule } from 'vee-validate'
import { required, email, confirmed, min, max } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
defineRule('required', required)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('max', max)
defineRule('email', email)
configure({
  generateMessage: localize({
    en,
  }),
})

import { createPinia } from 'pinia'

const pinia = createPinia()

const mountEl = document.querySelector('#app')
const app = createApp(App, { ...mountEl.dataset })

app.use(pinia).use(router).use(bugsnagVue).use(vClickOutside).mount('#app')
