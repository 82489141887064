<script setup lang="ts">
import { computed } from 'vue'

import { useRoute } from 'vue-router'

import { useStore } from '@/store/main'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import Icon from '@/components/Icon.vue'
import TextLoading from '@/components/TextLoading.vue'

defineProps({
  mobile: Boolean,
  loading: Boolean,
})

const route = useRoute()
const store = useStore()
const authStore = useAuthStore()

const { accountName, userName } = storeToRefs(authStore)

// Account Deep Dive breadcrumb variation
const { currentAccount, routeLoading } = storeToRefs(store)
const showAccountName = computed(() => {
  if (route.name === 'Account Deep Dive') {
    return true
  }
  return false
})

const accountDisplayName = computed(() => {
  return currentAccount?.value?.account_display || ''
})

// Upsell breadcrumb variation
const showUpsell = computed(() => {
  if (route.name === 'Upsell') {
    return true
  }
  return false
})
</script>

<template>
  <div class="breadcrumb">
    <div class="breadcrumb-container">
      <div v-if="showUpsell" data-test="upsell-breadcrumb">
        <span
          >Add-on: <span class="capitalize">{{ route.params.slug }}</span></span
        >
      </div>

      <div v-else-if="showAccountName">
        <span class="text-ice-grey-medium">Account: </span>
        <span
          v-if="!routeLoading && accountDisplayName"
          data-test="breadcrumb-account-name"
          >{{ accountDisplayName }}</span
        >
        <span v-else class="ml-3 inline-block"
          ><TextLoading :min="700" :max="800"
        /></span>
      </div>

      <div v-else>
        <span v-if="routeLoading" class="ml-3 inline-block"
          ><TextLoading :min="700" :max="800"
        /></span>
        <div v-else>{{ route.name }}</div>
      </div>

      <div v-if="!mobile" class="account-info">
        <Menu v-slot="{ open }">
          <MenuButton
            :disabled="loading"
            class="account-info__button"
            :class="{
              'account-info__button--loading cursor-not-allowed': loading,
            }"
          >
            <div v-if="loading" class="grow">
              <TextLoading :min="70" :max="100" />
              <TextLoading :min="70" :max="100" class="mt-1" />
            </div>
            <div v-else>
              <div class="text-left">{{ userName }}</div>
              <!-- This should come from the user auth object's affiliation. The user who is logged in -->
              <div class="text-left italic">{{ accountName }}</div>
            </div>
            <Icon
              name="expand_more"
              :class="{
                'transform rotate-180': open,
              }"
            />
          </MenuButton>

          <MenuItems>
            <MenuItem class="account-info__dropdown">
              <router-link to="/logout">Logout</router-link>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.breadcrumb {
  @apply border-b-[1px] border-light-grey;
}

.breadcrumb-container {
  @apply flex items-center justify-between px-3 h-[67px];

  @include bp('sm') {
    @apply px-12;
  }
}

.account-info {
  @apply text-xs relative items-center cursor-pointer;

  span {
    @apply ml-3;
  }
}

.account-info__button {
  @apply flex items-center;
}

.account-info__button--loading {
  min-width: 150px;
}

.account-info__dropdown {
  @apply p-3 absolute;
  top: calc(100% + 10px);
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.29);
  border-radius: 3px;
  width: 100%;
}
</style>
