import { defineStore } from 'pinia'
import axios from '@/utils/axios'
import type { AxiosResponse, AxiosError } from 'axios'
import { useStore } from '@/store/main'
import Cookies from 'js-cookie'
import router, { ROUTE_PATHS } from '@/router'
import { COOKIE_NAMES } from '@/constants'
import jwtDecode from 'jwt-decode'

// This package adds a polyfill for the locks API
import 'navigator.locks'
import type { AuthUser } from '@/types/Platform'
import Bugsnag from '@bugsnag/js'
import { asyncRequest } from '@/composables/asyncRequest'
import track from '@/services/track'

import { isEmpty } from 'lodash-es'

type LoginReponse = {
  success: boolean
  refresh_token: string
  token: string
}

type AuthResponse = { success: boolean } & { user: AuthUser }

const ALLOWED_DOMAIN = import.meta.env.VITE_ALLOWED_DOMAIN

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      // Note - because we do not have the full object at startup, nested changes are not reactive!
      // This shouldn't be an issue because we don't refetch the platform auth after startup.
      user: null as AuthUser | null,
      token: '',
      refreshToken: '',
      loggedIn: false,
      // This is to ensure that we don't call the initializeAuth() method more than once
      initialized: false,
    }
  },
  actions: {
    async login({ email, password }: { email: string; password: string }) {
      try {
        const result: AxiosResponse<LoginReponse> = await axios.post('/login', {
          email: email,
          password: password,
        })

        if (result.data.success) {
          const { refresh_token: refreshToken, token } = result.data
          this.token = token
          this.refreshToken = refreshToken
          this.loggedIn = true

          if (isEmpty(this.user)) {
            await this.getUserData()

            track.trackLogin()
          }

          return {
            success: true,
          }
        } else {
          // handle error
          return {
            success: false,
          }
        }
      } catch (e) {
        return {
          success: false,
          error: e,
        }
      }
    },
    async resetAuth() {
      // Cookies should be cleared at this point, but this will manually clear any that may be left if logout API request fails
      this.clearCookies()

      const store = useStore()
      this.$reset()
      // Clear main store as well
      store.$reset()

      // Set layout name after clearing store so login page display correctly
      store.layoutName = 'Public'

      analytics.reset()
    },
    async logout() {
      // skip this if we are not logged in?
      try {
        await axios.post('/logout')

        track.trackLogout(true)
      } catch (e) {
        // API can fail w 401 if the token is expired
        console.error(e)
      } finally {
        await this.resetAuth()
      }
    },
    async refresh() {
      if (!this.loggedIn) {
        // We shouldn't attempt this if the user is not logged in.
        // Gets us out of bad situations with accessible domains.
        return false
      }

      let success = false
      const initialRefreshToken = this.getCookieRefreshToken()

      await navigator.locks.request(
        'refresh_lock',
        // https://developer.mozilla.org/en-US/docs/Web/API/LockManager/request#ifavailable_example
        { ifAvailable: true },
        async (lock) => {
          if (lock) {
            // The lock has been acquired. This is the primary tab. It will do the refresh.
            console.log('🔄 refreshing token')
            await this.handleRefresh()
          } else {
            // All other tabs run this code.
            console.log('⏳ another tab is refreshing the token')
            // Wait for the refresh attempt to complete on the main tab.
            await navigator.locks.request('refresh_lock', () => {
              // Do nothing. This waits for the lock to be released
            })
          }
          // The refresh attempt is complete, whether by this tab or another.
          // This code is the same for all tabs.
          // If the refresh was successful, we should still have cookies set and the refresh token should be different.
          const refreshToken = this.getCookieRefreshToken()

          if (refreshToken && refreshToken !== initialRefreshToken) {
            success = true
          } else {
            success = false

            // If the refresh failed, we should log the user out
            // If they still have a refresh token in their cookies
            // something is wrong on the server side and we shouldn't redirect
            if (!refreshToken) {
              console.log('🔒 refresh failed, logging out')

              track.trackLogout(false)

              // Handle clearing the store and redirecting to login
              await this.resetAuth()
              await router.push(ROUTE_PATHS.LOGIN)
            }
          }
        }
      )

      return success
    },
    async handleRefresh() {
      // Ensure we have the latest token
      const refreshToken = this.getCookieRefreshToken()

      try {
        const result: AxiosResponse<LoginReponse> = await axios.post(
          '/refresh',
          null,
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        )

        if (result.data.success) {
          const { refresh_token: refreshToken, token } = result.data
          this.token = token
          this.refreshToken = refreshToken
        }
      } catch (e) {
        // If it's an axios error and failing with 401, don't report to Bugsnag
        if (axios.isAxiosError(e) && e.response?.status !== 401) {
          Bugsnag.notify(e as AxiosError)
        } else if (!axios.isAxiosError(e)) {
          Bugsnag.notify(e as Error)
        }
      }
    },
    async getUserData() {
      const { res } = await asyncRequest<AuthResponse>({
        url: '/proxy/user/auth',
        method: 'post',
      })

      if (res) {
        this.user = res.data.user

        const stringId = `${this.user.id}`

        Bugsnag.setUser(stringId)

        const account_id = this.userAccountId

        analytics.identify(stringId, {
          user_id: this.user.id,
          affiliation_name: this.user.current_affiliation.name || null,
          affiliation_id: this.user.current_affiliation.id || null,
          account_id: account_id || null,
          is_internal: Boolean(
            this.user.is_internal || this.user.is_internal_customer
          ),
        })

        // This event is used to know when a user refreshes their session/is active again
        track.trackUserAccessed()
      }
    },
    updateStateFromCookies() {
      const token = this.getCookieToken()
      const refreshToken = this.getCookieRefreshToken()

      if (!refreshToken) {
        return false
      }

      // set token and refreshToken
      // remember - token cookie expires sooner than refresh token. this may be null
      this.token = token || ''
      this.refreshToken = refreshToken
      return true
    },
    async initializeAuth() // {
    // routeRequiresAuth,
    // path,
    // }: {
    //   routeRequiresAuth: boolean
    //   path: string
    // }
    {
      this.initialized = true

      const updated = this.updateStateFromCookies()
      if (!updated) {
        // Wrap this in a try to avoid errors caused by logging
        // try {
        //   // Avoid logging if the route doesn't require auth, or it's the home page (which will be frequently visited)
        //   // It was way too noisy
        //   if (routeRequiresAuth && path !== ROUTE_PATHS.HOME) {
        //     Bugsnag.notify('Auth Error - No refresh token cookie', (event) => {
        //       // get cookies & add to metadata
        //       const currentCookies = Cookies.get()
        //       event.addMetadata('cookies', currentCookies)
        //     })
        //   }
        // } catch (e) {
        //   console.error(e)
        // }

        return false
      }

      // jwt decode refresh token
      // get acc property from jwt
      let decodedToken: { acc?: string[] } = {}
      try {
        decodedToken = jwtDecode(this.refreshToken)
      } catch (e) {
        // If the refresh token is invalid, treat the user as logged out
        Bugsnag.notify(e as Error)
        this.loggedIn = false
        return false
      }

      const accessibleDomains = decodedToken.acc || []

      if (!accessibleDomains?.includes(ALLOWED_DOMAIN)) {
        this.loggedIn = false

        // Wrap this in a try to avoid errors caused by logging
        // try {
        //   Bugsnag.notify('Auth Error - Accessible Domains', (event) => {
        //     event.addMetadata('Data', {
        //       decodedToken,
        //       accessibleDomains,
        //       refreshToken: this.refreshToken,
        //     })
        //   })
        // } catch (e) {
        //   console.error(e)
        // }

        return false
      }

      this.loggedIn = true
      return true
    },
    getCookieToken: () => {
      return Cookies.get(COOKIE_NAMES.TOKEN)
    },
    getCookieRefreshToken: () => {
      return Cookies.get(COOKIE_NAMES.REFRESH_TOKEN)
    },
    clearCookies() {
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        Cookies.remove(cookieName, { path: '/', domain: '.the-atlas.com' })
      })
    },
  },
  getters: {
    accountName: (state) => {
      const { user } = state
      if (!user) return ''

      return user.account_name || user.current_affiliation?.name
    },
    userName: (state) => {
      const { user } = state
      if (!user) return ''

      return user.name
    },
    userAccountId: (state) => {
      const { user } = state
      if (!user) return ''

      if (user.account_id) {
        return user.account_id
      }

      const store = useStore()
      const { platformAuth } = store
      if (platformAuth.user?.id === user.id) {
        return platformAuth.account.id
      }
    },
  },
})
